import CloseIcon from "@mui/icons-material/Close";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import ExploreIcon from "@mui/icons-material/Explore";
import InsightsIcon from "@mui/icons-material/Insights";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import UpdateIcon from "@mui/icons-material/Update";
import { Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import WordCloud from "react-wordcloud";
import AscendoHat from "../../assets/custexp/AscendoHat";
import Amazon from "../../assets/custexp/amazon.png";
import Apple from "../../assets/custexp/apple.png";
import Flipkart from "../../assets/custexp/flipkart.png";
import Google from "../../assets/custexp/google.png";
import Microsoft from "../../assets/custexp/microsoft.png";
import Netflix from "../../assets/custexp/netflix.png";
import Oracle from "../../assets/custexp/oracle.png";
import Pepsi from "../../assets/custexp/pepsi.png";
import Philips from "../../assets/custexp/philips.png";
import Telsa from "../../assets/custexp/tesla.png";
import Walmart from "../../assets/custexp/walmart.png";
import Linecharts from "../../components/highcharts/linecharts";
import "./customerexperience.scss";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function Customerexperience() {
  const [option, setOption] = React.useState("");

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const [open, setOpen] = useState(false);

  const toggleDrawer = (state) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(state);
  };

  const words = [
    { text: "Update payment information", value: 10 },
    { text: "Failure", value: 8 },
    { text: "kami cloud payment method chatbot", value: 7 },
    { text: "Bug", value: 6 },
    { text: "payment come", value: 5 },
    { text: "pending request delete 3999 payment", value: 9 },
    { text: "Configuration", value: 4 },
    { text: "Network", value: 6 },
  ];

  const wordCloudOptions = {
    rotations: 2,
    rotationAngles: [0, 0],
    fontSizes: [15, 40],
  };

  const chartData = {
    x_title: "Date",
    y_title: "Value",
    x_axis: [
      "2024-02-01",
      "2024-02-02",
      "2024-02-03",
      "2024-02-04",
      "2024-02-05",
    ],
    y_axis: [
      {
        name: "Series 1",
        data: [10, 15, 8, 12, 18],
      },
    ],
  };
  const chartData4 = {
    x_title: "Date",
    y_title: "Value",
    x_axis: [
      "2024-02-16",
      "2024-02-17",
      "2024-02-18",
      "2024-02-19",
      "2024-02-20",
    ],
    y_axis: [
      {
        name: "Positive",
        data: [17, 25, 10, 17, 32],
      },
      {
        name: "Neutral",
        data: [5, 12, 6, 13, 20],
      },
      {
        name: "Negative",
        data: [3, 9, 8, 6, 4],
      },
    ],
  };
  return (
    <div
      className="customerexperienceWrap"
      style={{ overflow: "scroll", height: "calc(100vh - 70px)" }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Customer Experience
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Track Your Progress and Reclaim the best customer experience!
            </Typography>
          </div>
          <div className="dropdownWrap" style={{ position: "relative" }}></div>
        </div>
        <Grid>
          <Box
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
              marginBottom: "15px",
            }}
            noValidate
            autoComplete="off"
          >
            {/* TextField (80%) */}
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              fullWidth
              sx={{
                flex: 4,
                "& .MuiInputBase-root": {
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                },
                "& .MuiInputLabel-root": { top: "-5px" }, // Align label properly
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />

            {/* Select Dropdown (20%) */}
            <FormControl sx={{ flex: 1 }}>
              <InputLabel sx={{ top: "-5px" }}>Sort by</InputLabel>
              <Select
                value={option}
                onChange={handleChange}
                sx={{
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  "& .MuiInputBase-root": { height: 40 },
                }}
              >
                <MenuItem value="option1">Name Ascending</MenuItem>
                <MenuItem value="option2">Name Descending</MenuItem>
                <MenuItem value="option3">Interaction Low to High</MenuItem>
                <MenuItem value="option4">Interaction High to Low</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Netflix} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Netflix</Typography>
                        <Typography className="interactionName">
                          <span>113</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">23:45</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText greenText">
                              93
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">27</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Amazon} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Amazon</Typography>
                        <Typography className="interactionName">
                          <span>23</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">41:05</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText greenText">
                              62
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">21</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div
                    className="innerCard ascendoPanel"
                    onClick={toggleDrawer(true)}
                  >
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <AscendoHat />
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">
                          Ascendo.ai
                        </Typography>
                        <Typography className="interactionName">
                          <span>134</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">12:19</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          <div>
                            <Typography className="boldText greenText">
                              97
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">04</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Telsa} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Telsa</Typography>
                        <Typography className="interactionName">
                          <span>45</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">33:05</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText greenText">
                              93
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">27</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Apple} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Apple</Typography>
                        <Typography className="interactionName">
                          <span>82</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">23:45</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText greenText">
                              93
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">27</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Google} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Google</Typography>
                        <Typography className="interactionName">
                          <span>11</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">23:45</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText redText">
                              12
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">60</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Microsoft} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">
                          Microsoft
                        </Typography>
                        <Typography className="interactionName">
                          <span>74</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">54:23</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText orangeText">
                              52
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">57</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Flipkart} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">
                          Flipkart
                        </Typography>
                        <Typography className="interactionName">
                          <span>113</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">23:45</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText greenText">
                              87
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">17</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Philips} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Philips</Typography>
                        <Typography className="interactionName">
                          <span>94</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">37:25</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText redText">
                              12
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">76</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Pepsi} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Pepsi</Typography>
                        <Typography className="interactionName">
                          <span>113</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">23:45</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText greenText">
                              93
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">27</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Oracle} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Oracle</Typography>
                        <Typography className="interactionName">
                          <span>113</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">23:45</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText greenText">
                              93
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">27</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid xs={3}>
                <Item>
                  <div className="innerCard">
                    <div className="d-flex marBot15">
                      <Box className="imgWrap">
                        <img src={Walmart} />{" "}
                      </Box>
                      <Box className="textWrap">
                        <Typography className="companyName">Walmart</Typography>
                        <Typography className="interactionName">
                          <span>113</span> Interactions
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={4}>
                          <div>
                            <Typography className="boldText">12:45</Typography>
                            <Typography className="semiBoldtext">
                              Hours Worked
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4} className="borderLr">
                          {" "}
                          <div>
                            <Typography className="boldText orangeText">
                              51
                            </Typography>
                            <Typography className="semiBoldtext">
                              Service Index
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={4}>
                          {" "}
                          <div>
                            <Typography className="boldText">27</Typography>
                            <Typography className="semiBoldtext">
                              Escalations
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
      <Drawer
        sx={{
          "& .MuiPaper-root.MuiDrawer-paper ": {
            width: "calc(100% - 200px)",
            padding: "16px",
            borderRadius: "8px 0px 0px 0px",
          },
          zIndex: 9999,
        }}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Grid>
          <Grid sx={{ position: "relative" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
              Customer Experience
            </Typography>

            <IconButton
            onClick={toggleDrawer(false)}
              sx={{ position: "absolute", right: "0px", top: "-5px" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider />
          <Box
            sx={{ display: "flex", justifyContent: "center" }}
            className="companyDetails"
          >
            <span>
              <AscendoHat />
            </span>
            <Typography className="companyNametext">Ascendo.ai</Typography>
            <Typography className="hourWorked">12:19 Hours Worked</Typography>
          </Box>

          <Grid>
            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="greenText">97</Typography>
                        <Typography className="interactionText">
                          Service Index
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<UpdateIcon />}>Update</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="blueText">134</Typography>
                        <Typography className="interactionText">
                          Total Interactions
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<ExploreIcon />}>Explore</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="blueText">31</Typography>
                        <Typography className="interactionText">
                          Trending issues
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<TuneIcon />}>Optimize</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="blueText">4</Typography>
                        <Typography className="interactionText">
                          Escalations
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<EmojiObjectsIcon />}>
                          Understand
                        </Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="blueText">04:15:36</Typography>
                        <Typography className="interactionText">
                          Avg Response Time
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<InsightsIcon />}>Improve</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="blueText">14</Typography>
                        <Typography className="interactionText">
                          Missed SLA
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<InsightsIcon />}>Improve</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="greenText">6</Typography>
                        <Typography className="interactionText">
                          High Priority
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<ExploreIcon />}>Explore</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="greenText">12</Typography>
                        <Typography className="interactionText">
                          Above AHT
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<TuneIcon />}>Optimize</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="orangeText">Medium</Typography>
                        <Typography className="interactionText">CES</Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<ExploreIcon />}>Explore</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <Item>
                    <div className="innerdrawCard">
                      <div className="">
                        <Typography className="greenText">Good</Typography>
                        <Typography className="interactionText">
                          CSAT
                        </Typography>
                        <Divider />
                      </div>
                      <div className="cardItem">
                        <Button endIcon={<ExploreIcon />}>Explore</Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid container spacing={2} sx={{mt:"10px"}}>
            <Grid className="chartSize">
            <Card sx={{ p: "16px" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Problem Context
              </Typography>
              <Divider sx={{ my: "12px" }} />

             
                {" "}
                <WordCloud words={words} options={wordCloudOptions} />
              </Card>
            </Grid>

            <Grid className="chartSize">
            <Card sx={{ p: "16px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Sentiment Distribution
                  </Typography>
                  <Divider sx={{ my: "12px" }} />
                
                  <Linecharts value={chartData4} id="lineChart4" />
                </Card>
              </Grid>

          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
}

export default Customerexperience;
