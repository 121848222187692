import Highcharts from 'highcharts';
import React, { useEffect } from 'react';

function NobarCharts() {
  useEffect(() => {
    Highcharts.chart('containernobar', {
      chart: {
        type: 'column'
      },
      title: {
        text: '',
        align: 'left'
      },
      credits: {
        enabled: false
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      tooltip: {
        enabled: false
    },
      xAxis: {
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        lineWidth: 0, 
        categories: ['', '', ''],
        crosshair: true,
        accessibility: {
          description: ''
        }
      },
      yAxis: {
        labels: {
          enabled: false
        },
        min: 0,
        gridLineWidth: 0,
        title: {
          text: ''
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0.3,
          borderWidth: 0
        }
      },
      series: [
        {
          name: '',
          data: [2849, 2800, 1200]
        },
        {
          name: '',
          data: [2521, 2100, 2100] 
        }
      ]
    });
  }, []); 

  return (
    <div id="containernobar" style={{ width: '100%', height: '300px',opacity: '1' }}></div>
  );
}

export default NobarCharts;
