// StackedBarChart.js
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

const StackedBarChart = () => {
  const options = {
    chart: {
      type: 'bar',  // Bar chart type
      height: 125,  // Chart height
    },
    title: {
      text: '',  // No title for the chart
    },
    subtitle: {
      text: '',  // No subtitle for the chart
    },
    credits: {
      enabled: false,  // Disable Highcharts credits
    },
    xAxis: {
      visible: false,  // Hide the x-axis
      title: {
        text: null,  // Remove x-axis title
      },
    },
    yAxis: {
      visible: false,  // Hide the y-axis
      title: {
        text: null,  // Remove y-axis title
      },
    },
    plotOptions: {
      bar: {
        stacking: 'normal',  // Enable stacking of bars
      },
    },
    series: [
      {
        name: 'Scheduled',
        data: [5],  // Data for Category 1
        color: '#FF5733',  // Color for Category 1
      },
      {
        name: 'Completed',
        data: [4],  // Data for Category 2
        color: '#33CFFF',  // Color for Category 2
      },
      {
        name: 'Processing',
        data: [ 2],  // Data for Category 3
        color: '#FFC300',  // Color for Category 3
      },
      {
        name: 'Failed',
        data: [ 9],  // Data for Category 4
        color: '#DAF7A6',  // Color for Category 4
      },
    ],
  };

  return (
    <div style={{ width: '100%', height: '130px' }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
};

export default StackedBarChart;
