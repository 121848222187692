import Highcharts from 'highcharts';
import React, { useEffect } from 'react';

function NolineCharts() {
  useEffect(() => {
    Highcharts.chart('containernoLine', {
      title: {
        text: '',
        align: ''
      },
      credits: {
        enabled: false
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      tooltip: {
        enabled: false
    },
      yAxis: {
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        title: {
          text: ''
        }
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        lineWidth: 0, 
        tickLength: 0,
        accessibility: {
          rangeDescription: ''
        }
      },
      legend: {
        enabled: false,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 2010
        }
      },
      series: [{
        name: '',
        data: [
          93934, 28656, 65165, 81827
        ]
      }, {
        name: '',
        data: [
          65496, 43741, 98742, 39851
        ]
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    });
  }, []); // Empty dependency array to run once on component mount

  return (
    <div id="containernoLine" style={{ width: '100%', height: '300px',opacity: '1'}}></div>
  );
}

export default NolineCharts;
