import React from 'react'

function SlaIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.73 3.50989L15.49 7.02989C15.73 7.51989 16.37 7.98989 16.91 8.07989L20.1 8.60989C22.14 8.94989 22.62 10.4299 21.15 11.8899L18.67 14.3699C18.25 14.7899 18.02 15.5999 18.15 16.1799L18.86 19.2499C19.42 21.6799 18.13 22.6199 15.98 21.3499L12.99 19.5799C12.45 19.2599 11.56 19.2599 11.01 19.5799L8.02003 21.3499C5.88003 22.6199 4.58003 21.6699 5.14003 19.2499L5.85003 16.1799C5.98003 15.5999 5.75003 14.7899 5.33003 14.3699L2.85003 11.8899C1.39003 10.4299 1.86003 8.94989 3.90003 8.60989L7.09003 8.07989C7.62003 7.98989 8.26003 7.51989 8.50003 7.02989L10.26 3.50989C11.22 1.59989 12.78 1.59989 13.73 3.50989Z" fill="#F9BE02"/>
    </svg>
    
    
  )
}

export default SlaIcon