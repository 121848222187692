import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

const BubbleChart = () => {
    const options = {
        chart: {
            type: 'bubble',
            height: 350, // Keep the height of the chart at 350px
            zoomType: 'xy', // Allow zooming and panning if needed
            margin: [50, 50, 50, 50], // Add margins to prevent bubbles from getting cut off
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        credits: {
            enabled: false, // Disable Highcharts credits
        },
        xAxis: {
            visible: false, // Hide the x-axis line
            title: {
                text: null, // No title for x-axis
            },
        },
        yAxis: {
            visible: false, // Hide the y-axis line
            title: {
                text: null, // No title for y-axis
            },
        },
        plotOptions: {
            bubble: {
                minSize: '15%', // Set a smaller minimum bubble size to avoid overlap
                maxSize: '70%', // Limit the maximum size to avoid the bubbles being too large
            },
        },
        series: [
            {
                name: 'Slack',
                data: [
                    [10, 1, 12],  // [x, y, size]
                    [8, 4, 18],
                    [23, 7, 5],
                ],
                color: '#FF5733', // Color for small bubbles
                dataLabels: {
                    enabled: true,
                    format: '{point.z}', // Display size
                    style: {
                        color: 'white',
                        fontSize: '12px',
                    },
                },
            },
            {
                name: 'Data Safety',
                data: [
                    [12, 14, 25],
                    [8, 15, 35],
                ],
                color: '#33CFFF', // Color for medium bubbles
                dataLabels: {
                    enabled: true,
                    format: '{point.z}', // Display size
                    style: {
                        color: 'white',
                        fontSize: '12px',
                    },
                },
            },
            
            {
                name: 'Configuration',
                data: [
                    [18, 2, 12],
                    [9, 5, 23],
                    [14, 4, 15],
                ],
                color: '#DAF7A6', // Color for extra large bubbles
                dataLabels: {
                    enabled: true,
                    format: '{point.z}', // Display size
                    style: {
                        color: 'black',
                        fontSize: '14px',
                    },
                },
            },
            {
                name: 'Data Integration',
                data: [
                    [22, 20, 12],
                    [17, 14, 14],
                ],
                color: '#FFC300', // Color for large bubbles
                dataLabels: {
                    enabled: true,
                    format: '{point.z}', // Display size
                    style: {
                        color: 'black',
                        fontSize: '14px',
                    },
                },
            },
        ],
    };

    return (
        <div style={{ height: '350px' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default BubbleChart;
