import React from 'react'

function AscendoHat() {
  return (
    <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.00275594 5.61766L3.59574 8.88426C11.3324 4.91234 16.7673 7.83872 18.5183 9.79979L28.4631 0L0 5.61766H0.00275594Z" fill="url(#paint0_linear_13_19257)"/>
    <path d="M22.5216 26L28.4631 0L18.5156 9.79979C22.2738 14.607 20.4512 20.5566 19.069 22.9962L22.5244 26H22.5216Z" fill="url(#paint1_linear_13_19257)"/>
    <defs>
    <linearGradient id="paint0_linear_13_19257" x1="13.9562" y1="0.741277" x2="15.1203" y2="9.37242" gradientUnits="userSpaceOnUse">
    <stop stop-color="#314795"/>
    <stop offset="1" stop-color="#3E70B7"/>
    </linearGradient>
    <linearGradient id="paint1_linear_13_19257" x1="18.634" y1="6.43085" x2="30.4049" y2="17.9466" gradientUnits="userSpaceOnUse">
    <stop stop-color="#314795"/>
    <stop offset="1" stop-color="#3E70B7"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default AscendoHat