import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MyDateRangePicker from "../../components/datepicker/myDateRangePicker";
import "./supportquality.scss";

import { Close } from "@mui/icons-material";
import {
  Divider,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import WordCloud from "react-wordcloud";
import BadgeIcon from "../../assets/BadgeIcon";
import DowloadIcon from "../../assets/DowloadIcon";
import PredictionIconwhite from "../../assets/PredictionIconwhite";
import SearchIconwhite from "../../assets/SearchIconwhite";
import SlaIcon from "../../assets/SlaIcon";
import Uniquesearchwhite from "../../assets/Uniquesearchwhite";
import UpdateIcon from "../../assets/UpdateIcon";
import UserIconwhite from "../../assets/UserIconwhite";
import Linecharts from "../../components/highcharts/linecharts";

const rows = [
  {
    email: "email@gmail.com",
    Interaction: 101,
    bot: "0:00:28",
    agent: "0:00:28",
    Assigment: "0:00:28",
    Total: "0:00:00",
    AvgfirstTotal: "0:00:16",
    Totalhand: "0:00:08",
    Avghand: "0:00:30",
    Totalreply: "0:00:02",
    Avgreply: "0:00:10",
  },
  {
    email: "customer@gmail.com",
    Interaction: 28,
    bot: "0:00:98",
    agent: "00:01:28",
    Assigment: "00:05:78",
    Total: "0:00:00",
    AvgfirstTotal: "0:00:66",
    Totalhand: "0:00:28",
    Avghand: "0:00:09",
    Totalreply: "0:00:22",
    Avgreply: "0:00:50",
  },
  {
    email: "daniel@helx.com",
    Interaction: 3,
    bot: "0:00:00",
    agent: "0:00:00",
    Assigment: "0:00:00",
    Total: "0:00:20",
    AvgfirstTotal: "0:00:46",
    Totalhand: "0:01:08",
    Avghand: "0:00:89",
    Totalreply: "0:00:34",
    Avgreply: "0:02:20",
  },
  {
    email: "tate@helx.com",
    Interaction: 4,
    bot: "0:00:00",
    agent: "0:00:00",
    Assigment: "0:00:00",
    Total: "0:00:20",
    AvgfirstTotal: "0:00:16",
    Totalhand: "0:00:08",
    Avghand: "0:00:30",
    Totalreply: "0:00:02",
    Avgreply: "0:00:10",
  },
  {
    email: "alina@helx.com",
    Interaction: 5,
    bot: "0:00:00",
    agent: "0:00:00",
    Assigment: "0:00:00",
    Total: "0:00:20",
    AvgfirstTotal: "0:00:16",
    Totalhand: "0:00:08",
    Avghand: "0:00:30",
    Totalreply: "0:00:02",
    Avgreply: "0:00:10",
  },
  {
    email: "george@helx.com",
    Interaction: 3,
    bot: "0:00:00",
    agent: "0:00:00",
    Assigment: "0:00:00",
    Total: "0:00:20",
    AvgfirstTotal: "0:00:16",
    Totalhand: "0:00:28",
    Avghand: "0:00:07",
    Totalreply: "0:00:02",
    Avgreply: "0:00:10",
  },
  {
    email: "helen@helx.com",
    Interaction: 4,
    bot: "0:00:00",
    agent: "0:00:00",
    Assigment: "0:00:00",
    Total: "0:00:20",
    AvgfirstTotal: "0:00:16",
    Totalhand: "0:00:28",
    Avghand: "0:00:13",
    Totalreply: "0:00:20",
    Avgreply: "0:00:10",
  },
  {
    email: "max@helx.com",
    Interaction: 6,
    bot: "0:00:00",
    agent: "0:00:00",
    Assigment: "0:00:00",
    Total: "0:00:20",
    AvgfirstTotal: "0:00:06",
    Totalhand: "0:00:00",
    Avghand: "0:00:67",
    Totalreply: "0:00:02",
    Avgreply: "0:00:10",
  },
  {
    email: "christy@helx.com",
    Interaction: 2,
    bot: "0:00:00",
    agent: "0:00:00",
    Assigment: "0:00:00",
    Total: "0:00:20",
    AvgfirstTotal: "0:00:16",
    Totalhand: "0:00:18",
    Avghand: "0:00:35",
    Totalreply: "0:00:12",
    Avgreply: "0:00:10",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Regions = [{ label: "US" }, { label: "UK" }];
const Channels = [{ label: "AI Bot" }, { label: "URL Agent" }];
const Business = [{ label: "Health care" }, { label: "Service Based" }];

function Supportqualityview() {
  const dispatch = useDispatch();
  const [openDate, setOpenDate] = useState(false);
  const [dateLabel, setDateLabel] = useState("Select Date");
  const [openDrawer, setOpenDrawer] = useState(false);

  const words = [
    { text: "Error", value: 10 },
    { text: "Failure", value: 8 },
    { text: "Timeout", value: 7 },
    { text: "Bug", value: 6 },
    { text: "Performance", value: 5 },
    { text: "Crash", value: 9 },
    { text: "Configuration", value: 4 },
    { text: "Network", value: 6 },
  ];

  const wordCloudOptions = {
    rotations: 2,
    rotationAngles: [0, 0],
    fontSizes: [15, 40],
  };

  const chartData = {
    x_title: "Date",
    y_title: "Value",
    x_axis: [
      "2024-02-01",
      "2024-02-02",
      "2024-02-03",
      "2024-02-04",
      "2024-02-05",
    ],
    y_axis: [
      {
        name: "Series 1",
        data: [10, 15, 8, 12, 18],
      },
    ],
  };
  const chartData2 = {
    x_title: "Date",
    y_title: "Value",
    x_axis: [
      "2024-02-06",
      "2024-02-07",
      "2024-02-08",
      "2024-02-09",
      "2024-02-10",
    ],
    y_axis: [
      {
        name: "Series 2",
        data: [25, 13, 7, 3, 14],
      },
    ],
  };

  const chartData3 = {
    x_title: "Date",
    y_title: "Value",
    x_axis: [
      "2024-02-11",
      "2024-02-12",
      "2024-02-13",
      "2024-02-14",
      "2024-02-15",
    ],
    y_axis: [
      {
        name: "Series 3",
        data: [20, 4, 18, 7, 30],
      },
    ],
  };

  const chartData4 = {
    x_title: "Date",
    y_title: "Value",
    x_axis: [
      "2024-02-16",
      "2024-02-17",
      "2024-02-18",
      "2024-02-19",
      "2024-02-20",
    ],
    y_axis: [
      {
        name: "Overall",
        data: [17, 25, 10, 17, 32],
      },
      {
        name: "Bad",
        data: [15, 22, 6, 23, 30],
      },
      {
        name: "Neutral",
        data: [22, 24, 36, 28, 40],
      },
      {
        name: "Good",
        data: [32, 34, 26, 38, 50],
      },
      {
        name: "Excellent",
        data: [42, 64, 26, 58, 50],
      },
    ],
  };

  const innerRows = [
    {
      id: "001",
      interactions: (<div className="d-flexx">How to add presets <SlaIcon/></div>),
      hoursSpent: "00:08:34",
      topTechnician: "John Doe",
      sentiment: "Positive",
      CSAT: (
        <div className="d-flex ai-center">
          Awesome <span className='greenBg'>P</span>
        </div>
      ),
      CES:(<div  className="d-flexx">High<span className='redBg'>O</span></div>),
      context:"Slack Integration",
      category:"Resolution Prediction",
      subcategory:"Slack"
    },
    {
      id: "002",
      interactions: "Create a Default Preset for Playground",
        hoursSpent: "00:12:24",
      topTechnician: "Jane Smith",
      sentiment: "Neutral",
      CSAT: (<div className="d-flexx">Okay <span className='blueBg'>U</span></div>),
      CES:(<div className="d-flexx">Low<span className='greenBg'>U</span></div>),
      context:"Spares plan",
      category:"Spares",
      subcategory:"Planning"
    },
    {
      id: "003",
      interactions: (<div>How to Add Import Settings <span className="greenBg smallText">SLA</span></div>),
      hoursSpent: "00:04:22",
      topTechnician: "Robert Brown",
      CSAT:   (<div className="d-flexx">Okay <span className='orangeBg'>P</span></div>),
      CES:(<div className="d-flexx">Medium<span className='blueBg'>U</span></div>),
      sentiment: "Negative",
      context:"CRM Integration",
      category:"Resolution Prediction",
      subcategory:"General"
      
    },
    {
      id: "004",
      interactions: "How to Add is playground",
      hoursSpent: "00:07:32",
      topTechnician: "Emily Davis",
      CSAT: (<div className="d-flexx">Okay <span className='redBg'>O</span></div>),
      CES:(<div className="d-flexx">Low<span className='greenBg'>U</span></div>),
      sentiment: "Positive",
      context:"Salesforce",
      category:"Spares",
      subcategory:"Analysis"
    },
    {
      id: "005",
      interactions: (<div>How to Change threshold name <span className='redBg'>E</span></div>),
      hoursSpent: "00:06:21",
      topTechnician: "Michael Johnson",
      CSAT: (<div className="d-flexx">Okay <span className='blueBg'>P</span></div>),
      CES:(<div className="d-flexx">Low<span className='orangeBg'>P</span></div>),
      sentiment: "Neutral",
      context:"Data Upload",
      category:"Resolution Prediction",
      subcategory:"Planning"
    },
  ];

  const [date, setDate] = useState({ start_date: "", end_date: "" });

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleDateChange = (state) => {
    setDateLabel(
      `${moment(state.startDate).format("MM/DD/YYYY")} - ${moment(
        state.endDate
      ).format("MM/DD/YYYY")}`
    );
    setDate({
      start_date: moment(state.startDate).format("YYYY-MM-DD"),
      end_date: moment(state.endDate).format("YYYY-MM-DD"),
    });
  };

  return (
    <div
      className="SupportqualityWrap"
      style={{ overflow: "scroll", height: "calc(100vh - 70px)" }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Support Quality
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Support Quality Experience!
            </Typography>
          </div>
          <div
            className="dropdownWrap"
            style={{ position: "relative", display: "flex" }}
          >
            {[
              { label: "Regions", options: Regions },
              { label: "Channel", options: Channels },
              { label: "Business", options: Business },
            ].map((item, index) => (
              <Grid key={index} sx={{ ml: "10px" }}>
                <Autocomplete
                  disablePortal
                  options={item.options}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: 150 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={item.label}
                      sx={{
                        "& .MuiInputBase-root": { height: 40, minHeight: 40 },
                        "& .MuiOutlinedInput-root": {
                          height: 40,
                          "& input": { padding: "10px" },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            ))}
            <Grid sx={{ ml: "10px" }}>
              <Button
                fullWidth
                onClick={() => setOpenDate(true)}
                variant="outlined"
                endIcon={<CalendarMonthIcon />}
                sx={{
                  textTransform: "none",
                  textAlign: "left",
                  color: "grey",
                  height: "40px",
                  borderColor: "grey",
                  border: "1px solid grey",
                  backgroundColor: "transparent",
                }}
              >
                {dateLabel}
              </Button>
              {openDate && (
                <Grid
                  sx={{ position: "absolute", zIndex: 2, top: 0, right: 0 }}
                  onMouseLeave={() => setOpenDate(false)}
                >
                  <Card sx={{ p: "16px" }} elevation={10}>
                    <MyDateRangePicker onStateChange={handleDateChange} />
                  </Card>
                </Grid>
              )}
            </Grid>
          </div>
        </div>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Item sx={{ padding: "0" }}>
                <div className="innerCards orangeGrad">
                  <div className="textWrap">
                    <Typography className="numberTxt">67</Typography>
                    <Typography className="smallTxt">Users</Typography>
                  </div>
                  <div className="imageWrap">
                    <UserIconwhite />
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ padding: "0" }}>
                <div className="innerCards purpleGrad">
                  <div className="textWrap">
                    <Typography className="numberTxt">67</Typography>
                    <Typography className="smallTxt">Prediction</Typography>
                  </div>
                  <div className="imageWrap">
                    <PredictionIconwhite />
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ padding: "0" }}>
                <div className="innerCards blueGrad">
                  <div className="textWrap">
                    <Typography className="numberTxt">67</Typography>
                    <Typography className="smallTxt">Search</Typography>
                  </div>
                  <div className="imageWrap">
                    <SearchIconwhite />
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ padding: "0" }}>
                <div className="innerCards greenGrad">
                  <div className="textWrap">
                    <Typography className="numberTxt">67</Typography>
                    <Typography className="smallTxt">Unique Search</Typography>
                  </div>
                  <div className="imageWrap">
                    <Uniquesearchwhite />
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <Card sx={{ p: "16px", mt: "20px" }} className="dataTable">
          <Typography className="tableTitle">Data Table</Typography>
          <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                  
                      zIndex: 9999, // Ensure it stays above other cells while scrolling
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell>Interaction count</TableCell>
                  <TableCell>Bot Handling Time</TableCell>
                  <TableCell>Agent Requested Time</TableCell>
                  <TableCell>Assignment Time</TableCell>
                  <TableCell>Total First Response</TableCell>
                  <TableCell>Avg. First Response</TableCell>
                  <TableCell>Total Handling Time</TableCell>
                  <TableCell>Avg. Handling Time</TableCell>
                  <TableCell>Total Reply Time</TableCell>
                  <TableCell>Avg. Reply Time</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={handleOpenDrawer}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,// Optional: background color to cover the content behind it
                        zIndex: 1, // Ensure it stays above other cells while scrolling
                      }}
                    >
                      {row.email}{" "}
                      {index === 0 ||
                      index === 2 ||
                      index === 4 ||
                      index === 7 ? (
                        <BadgeIcon />
                      ) : null}
                    </TableCell>
                    <TableCell>{row.Interaction}</TableCell>
                    <TableCell>{row.bot}</TableCell>
                    <TableCell>{row.agent}</TableCell>
                    <TableCell>{row.Assigment}</TableCell>
                    <TableCell>{row.Total}</TableCell>
                    <TableCell>{row.AvgfirstTotal}</TableCell>
                    <TableCell>{row.Totalhand}</TableCell>
                    <TableCell>{row.Avghand}</TableCell>
                    <TableCell>{row.Totalreply}</TableCell>
                    <TableCell>{row.Avgreply}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid sx={{ display: "flex", mt: "20px" }}>
            <BadgeIcon />{" "}
            <Typography sx={{ fontSize: "13px" }}>- Top Technician </Typography>
          </Grid>
        </Card>
        <Drawer
          sx={{
            "& .MuiPaper-root.MuiDrawer-paper ": {
              width: "calc(100% - 200px)",
              padding: "16px",
              borderRadius: "8px 0px 0px 0px",
            },
            zIndex: 9999,
          }}
          anchor={"right"}
          open={openDrawer}
          onClose={handleCloseDrawer}
        >
          <IconButton
            sx={{ position: "absolute", right: "18px", top: "18px" }}
            onClick={handleCloseDrawer}
          >
            <Close />
          </IconButton>
          <Grid sx={{ p: "18px" }}>
            <Grid>
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                Agent Performance
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Review the agent the performance and reward them
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Card sx={{ p: "16px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Average first response time
                  </Typography>
                  <Divider sx={{ my: "12px" }} />
                  <Linecharts value={chartData} id="singleLineChart" />
                </Card>
              </Grid>
              <Grid item md={6}>
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Context of issues
                </Typography>
                <Divider sx={{ my: "12px" }} />

                <Card sx={{ p: "16px" }}>
                  {" "}
                  <WordCloud words={words} options={wordCloudOptions} />
                </Card>
              </Grid>
              <Grid item md={6}>
                <Card sx={{ p: "16px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Average handling times
                  </Typography>
                  <Divider sx={{ my: "12px" }} />

                  <Linecharts value={chartData2} id="lineChart2" />
                </Card>
              </Grid>
              <Grid item md={6}>
                <Card sx={{ p: "16px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Average reply times
                  </Typography>
                  <Divider sx={{ my: "12px" }} />

                  <Linecharts value={chartData3} id="lineChart3" />
                </Card>
              </Grid>
              <Grid item md={12}>
                <Card sx={{ p: "16px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Sentiment Distribution
                  </Typography>
                  <Divider sx={{ my: "12px" }} />

                  <Linecharts value={chartData4} id="lineChart4" />
                </Card>
              </Grid>

              <Grid item md={12}>
                <Card sx={{ p: "16px" }}>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      AI Explainability - Interactions
                    </Typography>
                    <div className="innerExporttwo">
                      <Tooltip title="Update">
                        <Button
                          className="exportIcon"
                          sx={{ textTransform: "none", color: "#071942" }}
                          startIcon={<UpdateIcon />}
                        >
                          Update
                        </Button>
                      </Tooltip>
                      <Tooltip title="Export">
                        <Button
                          className="exportIcon"
                          sx={{ textTransform: "none", color: "#071942" }}
                          startIcon={<DowloadIcon />}
                        >
                          Export
                        </Button>
                      </Tooltip>
                    </div>
                  </Grid>
                  <Divider sx={{ my: "12px" }} />

                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              position: "sticky",
                              left: 0, // Optional: To avoid background overlap when scrolling
                              zIndex: 999, // Keeps the cell above other cells
                            }}
                          >
                            ID
                          </TableCell>
                          <TableCell>Interactions</TableCell>
                          <TableCell>Hours Spent</TableCell>
                          <TableCell>Top Technician</TableCell>
                          <TableCell>CES</TableCell>
                          <TableCell>CSAT</TableCell>
                          <TableCell>Sentiment</TableCell>
                          <TableCell>Context</TableCell>
                          <TableCell>Category & Accuracy</TableCell>
                          <TableCell>Sub Category & Accuracy</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {innerRows.map((row, index) => (
                          <TableRow key={index} onClick={handleOpenDrawer}>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white",
                                zIndex: 0, // Makes sure this cell is below the header row when scrolling
                              }}
                            >
                              {row.id}
                            </TableCell>
                            <TableCell>{row.interactions}</TableCell>
                            <TableCell>{row.hoursSpent}</TableCell>
                            <TableCell>{row.topTechnician}</TableCell>
                            <TableCell>{row.CES}</TableCell>
                            <TableCell>{row.CSAT}</TableCell>
                            <TableCell>{row.sentiment}</TableCell>
                            <TableCell>{row.context}</TableCell>
                            <TableCell>{row.category}</TableCell>
                            <TableCell>{row.subcategory}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid sx={{ display: "flex", mt: "20px" }}>
            <Grid className="d-flexx" sx={{mr:"5px",pr:"10px",borderRight:"1px solid #bbb"}}><SlaIcon />{" "}
            <Typography sx={{ fontSize: "13px" }}> - Trending Issues </Typography>
            </Grid>
            <Grid className="d-flexx" sx={{mr:"5px",pr:"10px",borderRight:"1px solid #bbb"}}><span className="redBg">E</span>
            <Typography sx={{ fontSize: "13px" }}> - Trending Issues </Typography>
            </Grid>
            <Grid className="d-flexx" sx={{mr:"5px",pr:"10px",borderRight:"1px solid #bbb"}}><span className="greenBg smallText">SLA</span>
            <Typography sx={{ fontSize: "13px" }}> - Missed SLA </Typography>
            </Grid>
            <Grid className="d-flexx" sx={{mr:"5px",pr:"10px",borderRight:"1px solid #bbb"}}><span className="boldText">P</span>
            <Typography sx={{ fontSize: "13px" }}> - Predicted </Typography>
            </Grid>
            <Grid className="d-flexx" sx={{mr:"5px",pr:"10px",borderRight:"1px solid #bbb"}}><span className="boldText">U</span>
            <Typography sx={{ fontSize: "13px" }}> - User Survey </Typography>
            </Grid>
            <Grid className="d-flexx" sx={{mr:"5px",pr:"10px"}}><span className="boldText">O</span>
            <Typography sx={{ fontSize: "13px" }}> - Others </Typography>
            </Grid>
          </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Container>
    </div>
  );
}

export default Supportqualityview;
